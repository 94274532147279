body{
    color: black;
}
.w-45px{
    width: 45px;
    height: 1px;
    display: inline-block;
}
.bg-ffead9{
    background-color: #FFEAD9 !important;
}
.fw-900{
    font-weight: 900 !important;
}
.text-4d4d4d{
    color: #4D4D4D;
}
.GitHubIcon{
  color: black;
}
.align-center{
  display: flex;
  align-items: center;
}
.mt-135{
  margin-top: -135px !important;
}
.yellow-round{
    width: 85px;
    height: 85px;
    background-color: #FFB647;
    position: absolute;
    z-index: 10;
    left:-26px;
  top: 21px;
}
.yellow-round-small{
    width: 75px;
    height: 75px;
    background-color: #FFB647;
    position: absolute;
    z-index: 10;
    left:-26px;
  top: 31px;
}
.z-index-20{
    z-index: 20;
}
.border-width{
    border-width: 0.5px 0.5px 2px 0.5px !important;
    border-style: solid !important;
    border-color: #000000 !important;
}
.w-90{
    width: 90%;
}

.h-500px{
    height: 500px;
    object-fit: contain;
}
.bg-yellow{
    background: #FFB647;
}
.w-fit-content{
    width: fit-content;
}
.talk:hover{
background: #FFEAD9 !important;
border-width: 0.5px 0.5px 2px 0.5px !important;
border-style: solid !important;
border-color: #000000 !important;
color: black !important; 
transition: all 1.3s;
}
.talk:hover .u-arrow{
    display: inline-block !important;
}
.talk:hover .w-arrow{
    display: none !important;
}
.work:hover{
background: black !important;
color: white !important;
transition: all 1.3s;
}
.work:hover .w-arrow{
    display: inline-block !important;
}
.work:hover .u-arrow{
    display: none !important;
}
.experience{
    bottom: 0;
    right: 0;
}
.text-ffb647{
    color: #FFB647;
}
.w-82{
    width: 82%;
}
.bg-f2f2f2{
    background-color: #F2F2F2;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color:white !important;
    background-color:black !important;
  }
  .nav-link{
    background-color: white !important;
    color: black !important;
    width: 100%;
    margin-bottom: 15px;
  }

.nav-link.active .w-arrow {
    display: inline-block !important;
}
.nav-link.active .u-arrow {
    display: none !important;
}
.scrollspy-example {
  height: 350px;
  margin-top: .5rem;
  overflow: auto;
}
.simple-list-example-scrollspy .active {
  color:white !important;
  background-color:black !important;
}
.simple-list-example-scrollspy a{
  background-color: white !important;
  color: black !important;
  width: 100%;
  margin-bottom: 15px;
}
.simple-list-example-scrollspy .active .w-arrow {
  display: inline-block !important;
}
.simple-list-example-scrollspy .active .u-arrow {
  display: none !important;
}
  p{
    color: #4D4D4D;
  }
  .sm-w-100{
    width: 75% !important;
}
.yellow-round-small-all {
    width: 75px;
    height: 75px;
    background-color: #FFB647;
    position: absolute;
    z-index: 10;
    left: -12px;
    top: 54px;
}
.tab-pane {
   max-height: 342px;
    overflow: auto;
    overflow-x: hidden;
    overscroll-behavior-y:contain ;
  }
  .h-126px{
    height: 126px;
  }
  .h-126px:hover{
      background-color: #FFB647 !important;
      transition: all 1.3s;
  }
  .speciality{
    left: -11px;
  }
  .y-arrow{
    width: 15px;
  }
  .w-80{
    width: 80%;
  }
  .mh-240px{
     max-height: 240px;
     min-height: 150px;
     height: max-content;
  }
  .w-unset{
    width: unset !important;
  }
  .item p{
    height: 120px;
    overflow-y: auto;
  }
  .item h6{
    height: 42px;
  }
  .owl-next,.owl-prev{
   width: 40px;
   height: 40px;
   border-radius: 50%;
    background-color: #FFB647 !important;
    color: #6D4E00 !important;
    top: 130px;
  }
  .owl-prev{
    position: absolute;
    left: 10px;
  }
  .owl-next{
    position: absolute;
    right: 10px;
  }
  .owl-slider{
    overflow-x: hidden;
    width: 100%;
  }
  .form-control:focus {
    box-shadow:unset !important;
  }
  textarea{
    resize: none !important;
  }
  .submit{
    width: 150px;
  }
  .align-text{
    vertical-align: bottom;
  }
  .w-25px{
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
  .w-22px{
    width: 22px;
  }
  .text-ffead9{
    color: #FFEAD9;
  }
  .roundeddiv{
 border-radius: 3px;
  }
@media only screen and (max-width: 1100px) {
    .w-82{
        width: 90%;
    }
  }
  @media only screen and (max-width: 860px) {
    .font{
        font-size: 18px;
    }
  }  
  @media only screen and (max-width: 767px) {
    .font{
        font-size: 23px;
    }
  }  
  /* @media only screen and (max-width: 640px) {
    .item p{
        height: unset;
      }
  }   */
@media only screen and (max-width: 600px) {
    .h-500px{
        height: 300px;
    }
    .w-82{
        width: 100%;
    }
    .sm-w-100{
        width: 100% !important;
    }
    .w-80{
        width: 94%;
      }
      .w-fit-content{
        width: 90%;
    }
    .alignment-img{
      width: fit-content !important;
    }
    .me-1-c{
      margin-right: 0px;
  }
  }