.bg-ffead9{
    background-color: #FFEAD9;
}
header{
    height: 60px;
}
.h-60px{
    height: 60px;
}
.w-60px{
    width: 50px;
}
.w-80px{
    width: 80px;
}
.offcanvas-backdrop{
    display: none;
}
.offcanvas-backdrops {
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
.border-black-bottom{
    border-bottom: 1px solid #725B38;
}
a{
    text-decoration: none !important;
    color: black;
}
.navbar{
    cursor: pointer;
}
.dropdown-toggle::after{
    display: none;
}
.curser{
    cursor: pointer;
}
#staticBackdrop{
    height: 100% !important;
}
.slideright{
    opacity: 0;
    animation: slideRight 3s ease forwards;
}

.slideleft{
    animation: slideLeft 1s ease forwards;
    animation-delay: 1s;
    opacity: 0;
}
.portfolio{
    opacity: 0;
    animation: slideRight 2s ease forwards;
    animation-delay: 1s;
}

.slidebottom{
    opacity: 0;
    animation: slideBottom 1s ease forwards;
    animation-delay: .7s;
}
.me-1-c{
    margin-right: 1px;
}

/* animations */
@keyframes slideRight {
    0%{
        transform: translateX(-200px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideLeft {
    0%{
        transform: translateX(200px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideBottom {
    0%{
        transform: translateY(-200px);
        opacity: 0;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

.offcanvas-body a h5{
    padding: 2rem !important;
}
.offcanvas-body a{
    transition: 0.3s;
}
.offcanvas-body a:hover{
    background: white;
    
}
.offcanvas-body a:hover h5{
    color: black;
}